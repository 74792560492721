import React from 'react'
import Rapperswil from "./Rapperswil"
import Kirchweg from "./Kirchweg"
import Ossingen from "./Ossingen"
import Weisslingen from "./Weisslingen"
import OfferButtonSticky from "./OfferButtonSticky";
import { Helmet } from "react-helmet";


function Portfolio() {

  return (
	<div>
		{/* <Helmet>
            <title>Portfolio</title>
            <meta
              name="description"
              content="Schauen Sie sich unsere Visualisierungen an."
            />
            <meta
              name="keywords"
              content="portfolio, architektur, visualisierungen, vermarktung, wettbewerbe"
            />
          </Helmet> */}
		<OfferButtonSticky />
    <Rapperswil id="work" />
		<Weisslingen />
		<Kirchweg />
		<Ossingen />
	</div>
  );
}

export default Portfolio;
