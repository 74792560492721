import React, { useState, useEffect } from "react";
import { Container, Row, Image, Media } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

function Weisslingen() {
  const { t, i18n } = useTranslation();
  let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;
  const [textToggle, textToggleState] = useState(false);
  useEffect(() => {
    if (isMobile) {
      textToggleState(!textToggle);
    }
  }, []);

  return (
    <Container fluid className="main" style={{}}>
      <div className={isMobile ? "stickyMob" : "sticky"}>
        <div
          style={
            isMobile
              ? {}
              : { backgroundColor: "transparent", cursor: "default" }
          }
          className={textToggle ? "plusBox" : "infoBox"}
          onClick={isMobile ? () => textToggleState(!textToggle) : undefined}
        >
          {isMobile ? (
            <div
              style={textToggle ? {} : { display: "none" }}
              class="plusSymbol"
            >
              +
            </div>
          ) : undefined}
          <div style={textToggle ? { display: "none" } : {}}>
            {isMobile ? <h4>✕</h4> : undefined}

            <h5>{t("info.project")}</h5>
            <h6>Kiga Weisslingen</h6>

            <h5>{t("info.location")}</h5>
            <h6>Weisslingen, Schweiz</h6>

            <h5>{t("info.developer")}</h5>
            <a href="https://www.weisslingen.ch" target="_blank" className="info"><h6>Gemeinde Weisslingen</h6></a>

            <h5>Vertreten durch</h5>
            <a href="https://www.bbs-ing.ch" target="_blank" className="info"><h6>BBS Ingenieure</h6></a>

            <h5>{t("info.architecture")}</h5>
            <a href="https://lattmann.net/" target="_blank" className="info">
              <h6>Ruedi Lattmann Architektur und Design</h6>
            </a>

            <h5>{t("info.interior")}</h5>
            <a href="https://lattmann.net/" target="_blank" className="info">
              <h6>Ruedi Lattmann Architektur und Design</h6>
            </a>
          </div>
        </div>
      </div>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/weisslingen/Wettbewerb-Visualisierung-Kiga-Weisslingen-2.jpg"
              alt="Architekturvisualisierung"
            />
          </LazyLoad>
        </Media>
      </Row>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/weisslingen/Wettbewerb-Visualisierung-Kiga-Weisslingen-1.jpg"
              alt="Architekturvisualisierung"
            />
          </LazyLoad>
        </Media>
      </Row>
    </Container>
  );
}

export default Weisslingen;
